* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Rubik', sans-serif;
}

:root {
  --color-131921: #131921;
  --color-3b4149: #3b4149;
  --color-febd69: #febd69;
  --color-232f3e: #232f3e;
  --color-bf4800: #bf4800;
  --color-f5f5f7: #f5f5f7;
  --color-ededed: #ededed;
  --color-eaeaea: #eaeaea;
  --color-777777: #777777;
  --color-1c1c1b: #1c1c1b;
  --color-f2f2f2: #f2f2f2;
}

a {
  text-decoration: none;
  display: inline-block;
}

.card-wrapper {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}

.shorturl {
  display: none !important;
}

.button {
  background-color: var(--color-232f3e);
  color: white;
  font-size: 14px;
  padding: 13px 30px;
  border-radius: 25px;
}

.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}

input {
  width: 300px;
}

.footer .input-group-text {
  background-color: var(--color-232f3e);
  padding: 8px 17px;
  color: white;
}

.footer {
  background-color: var(--color-232f3e);
  margin-bottom: 0px;
  margin-top: 170px !important;
}
